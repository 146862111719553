import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import EmbedForm from "./EmbedForm.js";
import { Modal } from 'react-responsive-modal';
import { confirmAlert } from 'react-confirm-alert'; 


const EmbedDropdown = ({embed, refetchData, project_id, className}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);

  const confirmDelete = () => {
    confirmAlert({
      title: 'Confirm Delete',
      message: 'Are you sure you want to delete this embed?',
      buttons: [
        {
          label: 'No',
          className: 'btn background-3 btn-small',
          onClick: () => {} // Do nothing if "No" is clicked
        },
        {
          label: 'Yes',
          className: 'btn btn-danger btn-small',
          onClick: () => deleteEmbed()
        },
      ]
    });
  }

  const deleteEmbed = (evt) => {
    setDisabled(true);

    axios.delete(`/api/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}/embeds/${embed.token}`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Deleted");
        if(location.href.includes(embed.token)){
          history.push(`/o/${match.params.organization_id}/projects/${project_id || match.params.project_id}`)
        }
        refetchData();
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <div class={`btn-group hover-child-opacity ml-5 ${className}`}>
        <a type="button" class={`dropdown-toggle opacity-4 opacity-1-hover color-1 ${embed.sidenav ? "btn btn-small background-hover" : ""}`} data-toggle="dropdown" style={{paddingLeft: "10px"}}>
          <i class="fas fa-ellipsis-v"></i>
        </a>

        <ul class="dropdown-menu animated fadeInUp left-dropdown min-width-unset" role="menu">
          <li><a onClick={() => setOpen(true)}><i class="fal fa-pencil"></i>Edit</a></li>
          <li><a onClick={confirmDelete}><i class="fal fa-trash-alt"></i>Delete</a></li>
        </ul>
      </div>

      <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'modal-full-width'}} center>
        <EmbedForm closeModal={() => setOpen(false)} refetchData={refetchData} embedData={embed}/>
      </Modal>
    </React.Fragment>
  )
}

export default EmbedDropdown;