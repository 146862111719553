import React , { useState, useContext } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Btn from "../../Shared/Btn.js";
import PrivateForm from "../../Daas/Portal/Projects/TaskForms/PrivateForm.js";
import IconPicker from 'react-icons-picker'
import {ProjectContext} from "../../Projects/ProjectContext.js";

const EmbedForm = ({closeModal, refetchData, embedData, sidenav}) => {
  const match = useRouteMatch();
  const {fetchProjects} = useContext(ProjectContext);
  const [disabled, setDisabled] = useState(false);
  const [kind, setKind] = useState(embedData != null ? (embedData.iframe != null ? "embed" : "url") : "embed");
  const [url, setUrl] = useState(embedData != null ? embedData.url : null);
  const [directLink, setDirectLink] = useState(embedData != null ? embedData.direct_link : null);
  const [embed, setEmbed] = useState(embedData != null ? embedData.iframe : null)
  const [title, setTitle] = useState(embedData != null ? embedData.title : "");
  const [isPrivate, setIsPrivate] = useState(embedData != null ? embedData.private : false);
  const [location, setLocation] = useState("Sidenav");
  const [value, setValue] = React.useState(embedData != null ? embedData.icon : "FaUsers")

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.post(`/api/o/${match.params.organization_id}/embeds`, {
      project_id: match.params.project_id,
      title: title,
      iframe: embed, 
      url: url, 
      direct_link: directLink,
      private: isPrivate,
      sidenav: location === "Sidenav" ? true : false,
      icon: value
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        notice("Created embed");
        fetchProjects();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }
  
  const handleUpdate = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}/embeds/${embedData.token}`, {
      project_id: match.params.project_id,
      title: title,
      iframe: embed, 
      url: url, 
      private: isPrivate
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        refetchData(); 
        notice("Updated embed successfully");
        fetchProjects();
        closeModal();
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      setDisabled(false);
    });
  }

  return(
    <React.Fragment>
      <div >
        <div className="row" style={{height: "90vh", overflow: "auto"}}>
          <div className="col-3 embed-iframe-left">
            <div className="field">
              <label>Location</label>
              <select className="form-control" value={location} onChange={(e) => setLocation(e.target.value)}>
                <option value="Sidenav">Side Navigation</option>
                <option value="Tabs">Tabs</option>
              </select>
            </div>


            <div className="field">
              <label>Title</label>
              <div class="input-group display-flex">
                {location == "Sidenav" && 
                  <IconPicker value={value} onChange={(v) => setValue(v)} searchInputPlaceholder={"Type and click the eyegla"}/>
                }
                <input className="form-control" required="true" placeholder="Short title to put as a tab" value={title} onChange={(e) => setTitle(e.target.value)} />
              </div>
            </div>

            <div className="field">
              <div class="btn-group mb-25" style={{display: "flex", justifyContent: "space-around"}}>
                <a onClick={() => {setKind("url"); setEmbed(null); setDirectLink(null);}} type="button" class={`width-100-percent color-1 background-hover btn btn-small ${kind == "url" ? "background-active" : "border-all color-1"}`}>
                  Embed URL
                </a>

                <a onClick={() => {setKind("embed"); setUrl(null); setDirectLink(null);}} type="button" class={`width-100-percent color-1 background-hover btn btn-small ${kind == "embed" ? "background-active" : "border-all color-1"}`}>
                  Iframe code
                </a>

                <a onClick={() => {setKind("direct_link"); setEmbed(null); setUrl(null);}} type="button" class={`width-100-percent color-1 background-hover btn btn-small ${kind == "direct_link" ? "background-active" : "border-all color-1"}`}>
                  Direct link
                </a>
              </div>
            </div>

            <div className="field">
              {/* URL */}
              {kind === "url" && 
                <div className="field">
                  <label>Embed URL</label>
                    <input type="url" class="form-control" value={url} onChange={(e) => setUrl(e.target.value)} required="true"/>
                </div>
              }

              {/* Embed */}
              {kind === "embed" && 
                <div className="field">
                  <label>Embed code</label>
                  <textarea class="form-control" value={embed} onChange={(e) => setEmbed(e.target.value)} required="true" rows="5" resizable></textarea>
                </div>
              }

              {kind === "direct_link" && 
                <div className="field">
                  <label>Direct link</label>
                  <input type="url" class="form-control" value={directLink} onChange={(e) => setDirectLink(e.target.value)} required="true"/>
                </div>
              }
            </div>

            <div className="field text-right">
              {location == "Tabs" && 
                <PrivateForm isPrivate={isPrivate} setIsPrivate={setIsPrivate}/>
              }
              <button className="btn btn-primary btn-small" disabled={disabled || (title == "")} type="submit" onClick={embedData != null ? handleUpdate : handleSubmit}>Submit</button>
            </div>
          </div>

          <div className="col-9 default-padding border-all border-radius">
            {kind === "embed" && 
              <React.Fragment> 
                <div dangerouslySetInnerHTML={{ __html: embed }} />
              </React.Fragment>
            }

            {kind === "url" && 
              <React.Fragment>
                <iframe src={url} className="embed-iframe"></iframe>
              </React.Fragment>
            }

            {kind === "direct_link"&& 
              <div className="text-center large-padding">
                Direct link to site
              </div>
            }

            {(kind === null || kind === "")&& 
              <div className="text-center large-padding">
                Preview window
              </div>
            }
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EmbedForm;